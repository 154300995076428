@media screen and (min-width: 770px) {
    .navigation {
        font-family: 'Times New Roman', Times, serif;
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
    }
            ol, ul {
                list-style: none;
            }
                .nav-menu > ul {
                    display: inline-block;
                    width: 100%;
                    box-shadow: inset 0px 0px 1px 1px rgba(46,153,214, 0.9);
                    padding: 0 1.5em 0 1.5em;
                    background-color: rgba(46,153,214, 0.9);
                }
                    .nav-menu > ul > li {
                        display: inline-block;
                        text-align: center;
                        padding: 0 1.5em 0 1.5em;
                    }
                        .nav-menu > ul > li > ul {
                            display: none;
                        }
                            .nav-menu > ul > li > a, .nav-menu > ul > li > span {
                                display: block;
                                color: rgba(255, 255, 255, 0.75);
                                text-transform: uppercase;
                                text-decoration: none;
                                font-size: 0.7em;
                                letter-spacing: 0.25em;
                                height: 5em;
                                line-height: 5em;
                                -moz-transition: all .25s ease-in-out;
                                -webkit-transition: all .25s ease-in-out;
                                -o-transition: all .25s ease-in-out;
                                -ms-transition: all .25s ease-in-out;
                                transition: all .25s ease-in-out;
                                outline: 0;
                            }
                                .nav-menu > ul > li:hover > a {
                                    color: rgba(255, 255, 255, 1);
                                }
                                    .nav-menu > ul > li.active > a, .nav-menu > ul > li.active > span {
                                        color: rgba(46,153,214, 0.9);
                                    }
                                    .submenu {
                                        visibility: visible;
                                        opacity: 0;
                                        position: absolute;
                                        display: none;
                                        border: 1px solid rgba(46,153,214, 0.9);
                                        background-color: rgba(46,153,214, 0.9);
                                        max-width: 25rem;
                                        padding: 0.2rem;
                                        width: 12rem;
                                        list-style: none;
                                        line-height: 3rem;
                                        border-bottom-right-radius: 0.25rem;
                                        border-bottom-left-radius: 0.25rem;
                                    }
                                    .submenu li a {
                                        width: 100%;
                                        color: rgba(255, 255, 255, 0.75);
                                        border-top: solid 1px rgba(255, 255, 255, 0.15);
                                        display: block;
                                        text-align: center;
                                    }
                                    
                                    .submenu li:hover > a {
                                        color: rgba(255, 255, 255, 1);
                                    }
                                    li:hover .submenu  {
                                        visibility: visible;
                                        opacity: 1;
                                        display: block;
                                    }
                                    
                                    /*Nav button does not display for wide screens*/
                                    @media screen and (min-width: 770px) {
                                        .nav-button-container {
                                            display: none;
                                        }
                                    }     
                                }                
                                    
    @media screen and (max-width: 770px) {
    /*Set the nav menu when the screen size is smaller than 1200px*/
        .navigation {
            font-family: 'Times New Roman', Times, serif;
            margin: 0;
            padding: 0;
            border: 0;
            font-size: 100%;
            font: inherit;
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            text-align: center;
        }
            ol, ul {
                list-style: none;
            }
                .nav-menu.expanded ul {
                    display: flex;
                    position: fixed;
                    transform: translateX(0);
                    padding: 1em 1.5em 1em 1.5em;

                }
                .nav-menu ul {
                    position: fixed;
                    display: block;
                    flex-direction: column;
                    height: 100%;
                    width: 275px;
                    transform: translateX(-275px);
                    background-color: rgba(46,153,214, 1);
                    color: rgba(255, 255, 255, 0.75);
                    transition: all 0.5s ease;
                    overflow-y: auto;
                    top: 0;
                    z-index: 10002;
                    text-align: left;
                }
        
                .nav-menu > ul > li > a{
                    color: rgba(255, 255, 255, 0.75);
                }
                .nav-menu > ul > li:hover > a {
                    color: rgba(255, 255, 255, 1);
                }
                    .nav-menu > ul > li.active > a, .nav-menu > ul > li.active > span {
                        color: rgba(255, 255, 255, 1);
                    }
                    
                    .nav-menu.expanded .submenu {
                        position: relative;
                        height: auto;
                        width: max-content;
                        margin: 0;
                        text-align: left;
                    }
                    .nav-menu.expanded .submenu li a {
                        width: 100%;
                        color: rgba(255, 255, 255, 0.75);
                        border-top: solid 1px rgba(255, 255, 255, 0.15);
                        display: block;
                    }
                    .nav-menu.expanded .submenu li:hover > a {
                        color: rgba(255, 255, 255, 1);
                    }
                        .nav-menu > ul > li > a, .nav-menu > ul > li > span {
                            display: absolute;
                            position: relative;
                            color: rgba(255, 255, 255, 0.75);
                            text-transform: uppercase;
                            text-decoration: none;
                            font-size: 0.7em;
                            letter-spacing: 0.25em;
                            height: 5em;
                            line-height: 4em;
                            -moz-transition: all .25s ease-in-out;
                            -webkit-transition: all .25s ease-in-out;
                            -o-transition: all .25s ease-in-out;
                            -ms-transition: all .25s ease-in-out;
                            transition: all .25s ease-in-out;
                            outline: 0;
                        }
        
        /*Button icon for the small screen nav menu*/ 
        .nav-button-container {
            display: flex;
            position: absolute;
            right: 0.1rem;
            top: 0.1rem;
        }
            .nav-icon {
                display: flex;
                position: relative;
                align-items: center;
                justify-content: center;
                width: 1em;
                border-radius: 0.35rem;
                background-color: rgba(46,153,214, 0);
                transition: transform 2s ease;
            }
            .nav-icon:hover {
                color: rgba(46,153,214, 0.9);
                background-color: rgba(46,153,214, 0);
                transform: scale(1.2);
            }
            #nav-menu {
                transform: translateX(0);
            }
            #nav-expanded #page-wrapper {
                transition: all 1s ease;
            }
            #nav-expanded .nav-button-container {
                transform: translateX(0);
            }
    }
    /*Nav width for ultra small screens*/
    @media screen and (max-width: 280px ) {
        .nav-menu ul {
            width: 220px;
        }
    }